import React, { useState, useEffect } from 'react';
import { Navbar } from './navbar';
import { Inicio } from './inicio';
import { Nosotros } from './nosotros';
import { Founders } from './founders';
import { Proyectos } from './proyectos';
import { Servicios } from './servicio';
import { Contactanos } from './contactanos';


function App() {

  return (
    <React.Fragment>
      <Navbar />
      <Inicio />
      <Nosotros />
      <Founders />
      <Proyectos />
      <Servicios />
      <Contactanos />
      
    </React.Fragment>
  );
}

export default App;
