import React from 'react';
import './css/Inicio.css';
import logo from './img/undamLogo.png'; // Reemplaza esto con el logo de tu proyecto
import video from './img/fondo1.mp4';

function Inicio() {
  return (
       
    <div className="inicio-background" id="inicio">
      <video autoPlay loop muted id="video-background">
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
      </video>
      
      <div className="inicio-content">
        <img src={logo} className="inicio-logo" alt="Undam Logo" />
        <h1 className="inicio-title">¿Listo para dar el siguiente paso?</h1>
          <div className="button-container">
          <a href="#nosotros" className="inicio-button">Nosotros</a>
          <a href="#servicios" className="inicio-button">Servicios</a>
          <a href="#contactanos" className="inicio-button main-button">Contáctanos</a>
        </div>
      </div>
    </div>
  );
}

export { Inicio };
