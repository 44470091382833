import React from 'react';
import Slider from 'react-slick';
import logo1 from './img/proyec1.png';
import logo2 from './img/proyec2.png';
import logo3 from './img/proyec4.png';
import './css/proyectos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Proyectos() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div className='proyec_glo' id="proyectos">
      
      <Slider {...settings}>
        <div className='poyect1'>
          <img src={logo1} className='logo1' alt="Proyecto 1" />
          <h3 className='poyect'>Diseño de un sistema clasificador de cajas por medio de Red R-CNN y LSTM</h3>
          <p>La Red R-CNN detecta y localiza las cajas en las imágenes, mientras que la LSTM procesa la secuencia de información temporal para mejorar 
            la precisión de clasificación basándose en el contexto histórico de las cajas.</p>
        </div>
        <div className='poyect2'>
          <img src={logo2} className='logo2' alt="Proyecto 2" />
          <h3 className='poyect'>Chatbot de whatsapp para servicio técnico</h3>
          <p>El proyecto consistió en desarrollar un chatbot para automatizar el proceso de registro, seguimiento y gestión de visitas técnicas en una empresa de telecomunicaciones.</p>
        </div>
        <div className='poyect3'>
          <img src={logo3} className='logo3' alt="Proyecto 3" />
          <h3 className='poyect'>Sistema de Asistencia Médica Basado en IA para Diagnósticos Tempranos</h3>
          <p>Mejora en la precisión y rapidez de los diagnósticos, asistencia a médicos en la toma de decisiones 
            y potencial para salvar vidas mediante la detección temprana de enfermedades.</p>
        </div>
      </Slider>
    </div>
  );
}

export { Proyectos };
