import servio1 from './img/servico1.png';
import servio2 from './img/servicio2.png';
import servio3 from './img/servicio3.png';
import './css/servicio.css';

function Servicios() {
    return (
        <div className="servicios" id="servicios">
            <h1 className="servti">Servicios</h1>
            <div className="serviA">
                <div className='servicios1'>
                    <h2>Consultoría tecnológica y auditorías</h2>
                    <img src={servio1} className='serv2' alt="Consulta tecnológica" />
                </div>
                <div className='servicios2'>
                    <h2>Desarrollo de Software Personalizado</h2>
                    <img src={servio2} className='serv2' alt="Desarrollo de Software" />
                </div>
                <div className='servicios3'>
                    <h2>Implementación de Modelos AI</h2>
                    <img src={servio3} className='serv3' alt="Inteligencia Artificial" />
                </div>
            </div>
            
            <a href="#contactanos" className="contacto-btn">Contáctanos</a>
        </div>
    );
}

export { Servicios };
