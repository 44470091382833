import React, { useState } from 'react';
import './css/Contactanos.css';
import logo from './img/undamLogo.png';

function Contactanos() {
  // Aquí debes inicializar los hooks dentro del cuerpo de la función
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  

  const baseUrl = "http://localhost:3000";

  const sendEmail = async () => {
    let dataSend = {
      email: email,
      name: name,
      lastName: lastName,
      message: message
    };

    const res = await fetch(`${baseUrl}/email/sendEmail`, {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    // Handling response and possible errors
    if (res.status >= 200 && res.status < 300) {
      console.log("Send Successfully !");
    } else {
      console.log("Error sending email");
    }
  };

  return (
    <div className="contact-container" id="contactanos">
      <div className="contact-form">
        <h2>Contáctanos</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="input-group">
            <label>Name <span className="required">(required)</span></label>
            <div className="name-inputs">
              <input id="name" type="text" onChange={(e) => setName(e.target.value)} placeholder="First Name" required />
              <input id="lastName" type="text" onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" required />
            </div>
          </div>
          <div className="input-group">
            <label>Email <span className="required">(required)</span></label>
            <input id="mail" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
          </div>
          <div className="input-group">
            <label>Message <span className="required">(required)</span></label>
            <textarea id="message" type="text" onChange={(e) => setMessage(e.target.value)} placeholder="Your Message" required></textarea>
          </div>
          <button type="submit" onClick={sendEmail} className="send-button">SEND</button>
        </form>
      </div>
      <div className="logo-container">
        <img src={logo} className="logo" alt="Undam Logo" />
      </div>
    </div>
  );
}

export { Contactanos };

