import React from 'react';
import './css/Founders.css';
import founder1 from './img/majo.png';
import founder2 from './img/jose.jpg';
import founder3 from './img/duran.jpg';
import founder4 from './img/robert.jpg';

function Founders() {
  return (
    <div className="founders-container" id='founders'>
      
      <div className="founders-grid">
        <div className="founder">
          <img src={founder1} alt="Founder 1" className="founder-img" />
          <h3>María José Muñoz</h3>
          <p>Fundadora y Desarrolladora Fullstack con experiencia en IA y desarrollo frontend.</p>
          <a href="https://linkedin.com" className="linkedin-btn">LinkedIn</a>
        </div>
        <div className="founder">
          <img src={founder2} alt="Founder 2" className="founder-img" />
          <h3>José Rincón</h3>
          <p>Fundador y Desarrollador Fullstack, especializado en frontend y ciberseguridad.</p>
          <a href="https://linkedin.com" className="linkedin-btn">LinkedIn</a>
        </div>
        <div className="founder">
          <img src={founder3} alt="Founder 3" className="founder-img" />
          <h3>Juan David Durán</h3>
          <p>Fundador y Desarrollador Fullstack, especializado en desarrollo backend.</p>
          <a href="https://linkedin.com" className="linkedin-btn">LinkedIn</a>
        </div>
        <div className="founder">
          <img src={founder4} alt="Founder 4" className="founder-img" />
          <h3>Robert Castro</h3>
          <p>Fundador y Desarrollador Fullstack, enfocado en desarrollo backend.</p>
          <a href="https://linkedin.com" className="linkedin-btn">LinkedIn</a>
        </div>
      </div>
    </div>
  );
}

export { Founders };
