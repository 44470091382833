import React from 'react';
import './css/Navbar.css';
import logo from './img/undamLogo.png'; // Tu logo

function Navbar() {
  return (
    <nav className="navbar">
      {/* Logo que redirige al inicio */}
      <div>
        <a href="#inicio">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </a>
      </div>

      {/* Enlaces */}
      <div className="navbar-links">
        <a href="#nosotros">Nosotros</a>
        <a href="#founders">Founders</a>
        <a href="#proyectos">Proyecto</a>
        <a href="#servicios">Servicios</a>
        <a href="#contactanos">Contáctanos</a>
        
      </div>

      
    </nav>
  );
}

export { Navbar };
